import React, { useRef, useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getIndividualPost } from '../../services/guest/post'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import ProductCard from '../../components/Cards/ProductCard/ProductCard';
import { FiCalendar, FiMapPin, FiHeart, FiMessageCircle, FiExternalLink } from "react-icons/fi";
import { GoShareAndroid } from "react-icons/go";
import { initiateConversation } from '../../services/user/conversation';
import { toast } from 'sonner';

const PostDetails = () => {

  const { id } = useParams();

  const [post, setPost] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const navigate = useNavigate();

  const fetchPostDetails = async () => {
    const response = await getIndividualPost(id);
    if (response.status) {
      setPost(await response.data);

      let breadcrumbs = [];

      if (response.data.category) {
        breadcrumbs.push({
          label: 'Categories',
          path: '/categories',
          active: false
        });
        if (response.data.category.category) {
          breadcrumbs.push({
            label: response.data.category.category.name,
            path: `/category/${response.data.category.category.slug}`,
            active: false
          });
        }
        breadcrumbs.push({
          label: response.data.category.name,
          path: `/category/${response.data.category.slug}`,
          active: false
        });

        breadcrumbs.push({
          label: response.data.title,
          path: `/post/${id}`,
          active: true
        });
      }

      setBreadcrumbs(breadcrumbs);
    }

  }

  const handleInitiateConversation = async () => {
    const response = await initiateConversation({
      post_id: id,
    });
    if (response.status) {
      navigate(`/chats/${response.data.id}`)
    }
    else {
      toast.error(response.message)
    }
  }

  useEffect(() => {
    fetchPostDetails();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />
      <section>
        <div className='lg:container lg:py-10 md:py-10 sm:pt-0 sm:pb-5 lg:space-y-20 sm:space-y-10'>

          <div className='grid lg:grid-cols-4 sm:grid-cols-1 lg:gap-5 md:gap-5 sm:gap-0 relative'>

            <div className='lg:col-span-3 lg:space-y-5 sm:space-y-0'>

              <figure className='lg:shadow lg:border overflow-clip lg:rounded-lg sm:border-b'>
                {(post?.media.length === 0) ? <div>
                  <div className='lg:h-[400px] md:h-[350px] sm:h-[200px] w-full overflow-clip flex items-center justify-center bg-black'>
                    <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${post?.thumbnail_image}`} className='h-full w-fit' alt="" />
                  </div>
                </div> : <Swiper style={{
                  "--swiper-pagination-color": "#fff",
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-sides-offset": "30px",
                  "--swiper-navigation-size": "30px"
                }}
                  loop={true}
                  speed={600}
                  grabCursor={true}
                  navigation={true}
                  modules={[Navigation]}
                  className="select-none">
                  {post?.media.map((media) => <SwiperSlide key={media.id}>
                    <div className='lg:h-[400px] md:h-[350px] sm:h-[200px] w-full overflow-clip flex items-center justify-center bg-black'>
                      <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${media.path}`} className='h-full w-fit bg-white' alt="" />
                    </div>
                  </SwiperSlide>)}
                </Swiper>}
              </figure>

              <figure className='lg:shadow lg:border overflow-clip lg:rounded-lg sm:border-b'>
                <div className='lg:p-5 sm:p-4 space-y-3 border-b'>
                  <div>
                    <h1 className='lg:text-xl md:text-xl sm:text-lg text-gray-800 font-semibold'>{post?.title}</h1>
                  </div>
                  <div className='gap-2 flex items-center justify-start flex-wrap'>
                    <div className='flex items-center justify-start gap-1 text-gray-500'>
                      <span><FiMapPin strokeWidth={2} size={14} /></span>
                      <p className='lg:text-sm md:text-sm sm:text-xs'>{post?.address?.short_address}</p>
                    </div>
                    <div className='flex items-center justify-start gap-1 text-gray-500'>
                      <span><FiCalendar strokeWidth={2} size={14} /></span>
                      <p className='lg:text-sm md:text-sm sm:text-xs'>Posted {post?.posted_on?.ago}</p>
                    </div>
                  </div>
                  <div className='flex items-center justify-start gap-2'>
                    <h1 className='text-black font-bold lg:text-4xl md:text-4xl sm:text-3xl'>{process.env.REACT_APP_CURRENCY_SYMBOL + parseInt(post?.selling_price).toLocaleString('en-IN')}</h1>
                    {(post?.is_negotiable === "1") ? <span className='lg:text-xs sm:text-[0.5rem] px-3 py-1 bg-slate-100 rounded-lg'>Negotiable</span> : null}
                  </div>
                  <div className='flex lg:flex-row md:flex-row sm:flex-col lg:items-end md:items-end sm:items-start justify-between gap-3'>
                    <div className='lg:w-fit md:w-fit sm:w-full'>
                      <button onClick={() => handleInitiateConversation()} className='btn-primary-md w-full'>
                        <div className='flex items-center justify-center gap-2 px-5'>
                          <span>Chat with Seller</span>
                          <FiMessageCircle strokeWidth={3} size={20} />
                        </div>
                      </button>
                    </div>
                    <div className='flex items-center justify-start gap-3'>
                      <button className='bg-slate-100 px-2 py-1.5 text-xs font-medium flex items-center justify-center gap-1 rounded-md'>
                        <GoShareAndroid strokeWidth={1.5} size={13} />
                        Share Post
                      </button>
                      <button className='bg-slate-100 px-2 py-1.5 text-xs font-medium flex items-center justify-center gap-1 rounded-md'>
                        <FiHeart strokeWidth={2.5} size={13} />
                        Save For Later
                      </button>
                    </div>
                  </div>

                </div>
                <div className='lg:p-5 sm:p-4 space-y-4'>
                  {(post?.metadata) ? <div className='space-y-2'>
                    <p className='text-sm font-medium'>About Product</p>
                    <ul className='text-sm space-y-1'>
                      {Object.keys(post?.metadata).map((key, index) => {
                        return <li key={index}><span className='text-gray-500'>{key}</span> : <span className='text-gray-800'>{post?.metadata[key]}</span></li>
                      })}
                    </ul>
                  </div> : ''}
                  <div className='space-y-2'>
                    <p className='text-sm font-medium'>Description</p>
                    <p className='text-sm text-gray-500 leading-relaxed'>{post?.description}</p>
                  </div>
                </div>
              </figure>

              {post?.address?.place_id && <figure className='lg:shadow lg:border overflow-clip lg:rounded-lg sm:border-b'>
                <div className='lg:p-5 sm:p-4 border-b'>
                  <h3 className='font-medium'>Seller's Location</h3>
                </div>
                <div className='p-2'>
                  <iframe className='lg:h-[400px] sm:h-[300px] w-full outline-none rounded' src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=place_id:${post?.address?.place_id}`}></iframe>
                </div>
              </figure>}

            </div>

            <div className='position-sticky top-5 flex flex-col gap-5'>

              <figure className='lg:shadow lg:border overflow-clip lg:rounded-lg sm:border-b h-fit'>
                <div className='py-3 px-5 border-b text-center'>
                  <h3 className='font-medium'>About Seller</h3>
                </div>
                <div className='p-5 flex flex-col items-center justify-center gap-3'>
                  <div className='h-[80px] w-[80px] rounded-full overflow-clip flex items-center justify-center border'>
                    <img className='h-full w-auto' src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${post?.user?.profile_image}`} alt="" />
                  </div>
                  <div className='text-center space-y-1 w-full'>
                    <h4 className='text-sm font-semibold'>{post?.user?.name}</h4>
                    <h4 className='text-gray-500 text-xs flex items-center justify-center gap-1'>
                      <FiCalendar size={13} className='mt-[-2px]' />
                      <span>Registred on {post?.user?.registred_on}</span>
                    </h4>
                    <div className='pt-2 w-full'>
                      <Link>
                        <button className='btn-secondary-sm w-full flex items-center justify-center gap-1'>
                          <span>View Profile</span>
                          <FiExternalLink size={10} className='mt-[-1px]' />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </figure>


              <figure className='lg:shadow lg:border overflow-clip lg:rounded-lg sm:border-b h-fit'>
                <img src="/assets/download-app-sidebarnner.jpg" alt="" />
              </figure>


            </div>

          </div>



        </div>
      </section>
      <section>
        <div className='container lg:pb-10 md:pb-10 sm:pb-5'>

          <div className='lg:space-y-10 sm:space-y-7'>
            <div className="text-left space-y-2">
              <h1 className="lg:text-3xl md:text-3xl sm:text-2xl font-semibold text-slate-800">Related Posts</h1>
              <hr className="w-[100px] border border-ascent-dark" />
              <p className="lg:text-sm sm:text-xs text-gray-500">Explore more related posts to {post?.category.name}</p>
            </div>
            <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-3'>
              {post?.related_post.map((post) => <ProductCard key={post.id} post={post} />)}
            </div>
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default PostDetails
