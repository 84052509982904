import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, sendEmailVerificationOtp, userDetailsUpdate, verifyEmailVerificationOtp } from '../../../services/user/user';
import { AuthUserLogin } from '../../../redux/actions/AuthAction';
import { toast } from 'sonner';
import { BiLogOut } from 'react-icons/bi'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import { getIndividualSupportTicket } from '../../../services/user/supportTicket';
import moment from 'moment';

const SupportTicketDetails = () => {

  const auth = useSelector((state) => state.AuthReducer);

  const [supportTicket, setSupportTicket] = useState(null);

  const { ticket_id } = useParams();

  const fetchSupportTicket = async () => {
    const response = await getIndividualSupportTicket(ticket_id);
    if (response.status) {
      setSupportTicket(await response.data);
    }
  }

  useEffect(() => {
    fetchSupportTicket();
  }, []);

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <figure className='lg:border md:border sm:border-t lg:shadow md:shadow lg:rounded-lg md:rounded-lg overflow-clip'>
        <div className='lg:px-5 md:px-0 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Ticket Details</h1>
          <p className='text-xs text-gray-500'>Preview your ticket details</p>
        </div>
        <div className='lg:px-5 md:px-0 py-4 border-b space-y-3'>

          <div className='input-group'>
            <label htmlFor="title" className='text-sm font-medium mb-2'>Title</label>
            <input type="text" value={supportTicket?.title} className='input-box-md' required={true} readOnly={true} />
          </div>

          <div className='input-group'>
            <label htmlFor="title" className='text-sm font-medium mb-2'>Description</label>
            <textarea name="description" rows={5} value={supportTicket?.description} className='input-box-md' readOnly={true}></textarea>
          </div>

          <div className='input-group'>
            <label htmlFor="title" className='text-sm font-medium mb-2'>Date</label>
            <input type="text" value={moment(supportTicket?.created_at).format('D/M/YYYY, h:mm A')} className='input-box-md' required={true} readOnly={true} />
          </div>

          <div className='input-group'>
          <label htmlFor="title" className='text-sm font-medium mb-2'>Images Attatched</label>
            <div className='grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2
             gap-3'>
              {supportTicket?.media?.map((item, index) => <div className='rounded overflow-clip border' key={index}>
                <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${item?.path}`} className='h-full w-fit' alt="" />
              </div>)}
            </div>
          </div>


        </div>
      </figure>
    </React.Fragment>
  )
}

export default SupportTicketDetails