import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, sendEmailVerificationOtp, userDetailsUpdate, verifyEmailVerificationOtp } from '../../../services/user/user';
import { AuthUserLogin } from '../../../redux/actions/AuthAction';
import { toast } from 'sonner';
import { BiLogOut } from 'react-icons/bi'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

const EmailVerification = () => {

  const auth = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isOtpSent, setIsOtpSentState] = useState(false);

  const [inputFields, setInpuFields] = useState({
    email: '',
    otp: '',
    token: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInpuFields({ ...inputFields, [name]: value });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (isOtpSent) {
      const result = await verifyEmailVerificationOtp(inputFields);
      if (result.status) {
        toast.success(result.message);
        const auth_update = await getUserProfile();
        if (auth_update.status) {
            dispatch(AuthUserLogin(auth_update.data));
            navigate('/dashboard/account');
        }
        else {
            toast.error(auth_update.message);
        }
      }
      else {
        toast.error(result.message);
      }
    }
    else {
      const result = await sendEmailVerificationOtp();
      setIsOtpSentState(true);
      if (result.status) {
        setInpuFields({ ...inputFields, ['token']: result.data.token });
        toast.success(result.message);
      }
      else {
        toast.error(result.message);
      }
    }
  }

  useEffect(() => {
    if (auth.email_verified_at !== null) {
        navigate('/dashboard/account');
    }
    setInpuFields({ ...inputFields, ['email']: auth.email });
  }, [auth]);

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <figure className='lg:border md:border sm:border-t lg:shadow md:shadow lg:rounded-lg md:rounded-lg overflow-clip'>
          <div className='lg:px-5 md:px-5 py-4 space-y-1 border-b'>
            <h1 className='text-base font-medium'>Verify Email Address</h1>
            <p className='text-xs text-gray-500'>We will send a verification OTP to your registered email adddress</p>
          </div>
          <div className='lg:px-5 md:px-5 py-5 space-y-1'>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

            {isOtpSent ? <div className='input-group'>
                <label htmlFor="otp" className='input-label'>Verification OTP <em>*</em></label>
                <input onChange={(event) => handleInputChange(event)} value={inputFields.otp} type="number" name='otp' className='input-box-md' placeholder='OTP (6 Digits)' required pattern='[0-9]{6}' />
            </div> : <button type='submit' className='btn-primary-sm md:w-fit sm:w-full'>Send Verification OTP</button> }

            </div>
          </div>
          {isOtpSent && 
          <div className='lg:px-5 md:px-0 py-5 space-y-1 border-t'>
            <button type='submit' className='btn-primary-sm md:w-fit sm:w-full'>Verify OTP</button>
          </div> }
        </figure>
      </form>
    </React.Fragment>
  )
}

export default EmailVerification