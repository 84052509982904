import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { BsCartPlus } from "react-icons/bs";

const SidebarTab = ({ text, link, handleClick }) => {

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className='border-b pb-3'>
        <button onClick={link ? () => navigate(link) : handleClick} className='text-gray-800 capitalize flex items-center justify-between w-full'>
          <span className="text-base font-medium mt-1">{text}</span>
          <BsChevronRight strokeWidth={1} size={20} />
        </button>
      </div>
    </React.Fragment>
  )
}

const Sidebar = ({ state }) => {

  const auth = useSelector((state) => state.AuthReducer);

  return (
    <React.Fragment>
      <aside className={`sidebar ${state ? 'active' : 'inactive'}`}>
        <div className='mt-28 flex flex-col space-y-3 px-5 pt-2 max-h-[84vh] overflow-auto'>

          {auth
            ?
            <>
            <SidebarTab text={'My Account'} link={'/dashboard/account'} />
            <SidebarTab text={'My Posts'} link={'/dashboard/post'} />
            <SidebarTab text={'My Wishlist'} link={'/dashboard/wishlist'} />
            <SidebarTab text={'Account Settings'} link={'/dashboard/setting'} />
            <SidebarTab text={'Messages'} link={'/chats'} />
            </>
            :
            <div className='grid grid-cols-2 items-center justify-end gap-2 py-2'>
            <Link to={'/login'} ><button className='btn-primary-sm w-full'><span className='px-2'>Login</span></button></Link>
            <Link to={'/register'}><button className='btn-light-sm w-full'><span className='px-2'>Register</span></button></Link>
            </div>}
            
          <SidebarTab text={'Frequenty Asked Questions'} link={'/'} />            
          <SidebarTab text={'Help Center'} link={'/customer-support'} />

          <div className='py-1'>
            <Link to={'/post-item'} className='btn-primary-md flex items-center justify-center'><BsCartPlus strokeWidth={0.5} size={15} /><span className='px-2'>Start Selling</span></Link>
          </div>

        </div>
      </aside>
    </React.Fragment>
  )
}

export default Sidebar;