import React, { useEffect, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { FiChevronDown } from 'react-icons/fi';
import { getBrands, getCategories, getChildCategories, getIndividualCategory, getModels, getSpecifications, getTopCategories } from '../../../services/guest/category';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const FilterSidebar = () => {

  const { slug } = useParams();
  
  let [searchParams, setSearchParams] = useSearchParams();
  
  const navigate = useNavigate();
  
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCategories = async () => {
    const categories = await getCategories();
    if (categories.status) {
      setCategories(await categories.data);
    }
    const data = await getIndividualCategory(slug);
    if (data.status) {
      setSelectedCategory(await data.data);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [slug]);

  const handleCategoryChange = async (slug) => {
    setBrands([]);
    setSpecifications([]);
    setModels([]);
    const data = await getIndividualCategory(slug);
    if (data.status) {
      setSelectedCategory(await data.data);
    }
    const brands = await getBrands(await data.data.id);
    if (brands.status) {
      setBrands(await brands.data);
    }
    const specifications = await getSpecifications(await data.data.id);
    if (specifications.status) {
      const specifications_data = await specifications.data.filter((item) => {
        if (item.options.length !== 0) {
          return item;
        }
      })
      setSpecifications(await specifications_data);
    }
    navigate(`/category/${slug}`);
  }

  const handleBrandChange = async (brand_id) => {
    setModels([]);
    setSearchParams(params => {
      params.set("brand", brand_id);
      params.delete("model");
      return params;
    });
    const data = await getModels(brand_id);
    if (data.status) {
      setModels(await data.data);
    }
  }

  const handleModelChange = async (model_id) => {
    setSearchParams(params => {
      params.set("model", model_id);
      return params;
    });

    const handleModelChange = async (model_id) => {
      setSearchParams(params => {
        params.set("model", model_id);
        return params;
      });
    }
  }

  const handleSearchQueryChange = (event) => {
    const { value } = event.target;
    if (value === '') {
      setSearchParams(params => {
        params.delete("search_query");
        return params;
      });
    }
    else {
      setSearchParams(params => {
        params.set("search_query", value);
        return params;
      });
    }
  }

  return (
    <React.Fragment>
      <aside className='border shadow-sm divide-y rounded-md'>
        <div className='p-3'>
          <div className='input-group'>
            <label htmlFor="search_query" className='input-label'>Search Query</label>
            <input type="search" onChange={(event) => handleSearchQueryChange(event)} value={searchParams.get('search_query')} className='input-box-md' placeholder='Search Product' />
          </div>
        </div>
        <div className='p-4 space-y-2'>

          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <React.Fragment>
                <Disclosure.Button className={'w-full text-left flex items-center justify-between'}>
                  <h6 className='font-medium text-base'>Categories</h6>
                  <FiChevronDown className={open ? 'rotate-180 transform' : ''} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel>
                    <div className="space-y-1 border-t pt-3 max-h-[400px] overflow-y-scroll filter-scrollbar-div">
                      {categories.map((category) =>
                        <div key={category.id} className='flex flex-col gap-1'>
                          <div className='flex gap-1'>
                            <input checked={category.id === selectedCategory?.id} onChange={() => handleCategoryChange(category.slug)} type="checkbox" name='brand[]' value={category.id} id={`category_${category.id}`} />
                            <label className='text-sm font-medium cursor-pointer' htmlFor={`category_${category.id}`}>{category.name}</label>
                          </div>
                          {category.child_categories.length > 0 && <div className='flex flex-col gap-1 pl-3'>
                            {category.child_categories.map((child_category) =>
                            <div key={child_category.id} className='flex gap-1'>
                              <input checked={child_category.id === selectedCategory?.id} onChange={() => handleCategoryChange(child_category.slug)} type="checkbox" name='brand[]' value={child_category.id} id={`child_category_${child_category.id}`} />
                              <label className='text-sm font-medium cursor-pointer' htmlFor={`child_category_${child_category.id}`}>{child_category.name}</label>
                            </div>)}
                          </div> }
                        </div>)}
                    </div>

                  </Disclosure.Panel>
                </Transition>
              </React.Fragment>
            )}
          </Disclosure>
        </div>

        {(brands.length > 0) && 
        <div className='p-4 space-y-2'>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <React.Fragment>
                <Disclosure.Button className={'w-full text-left flex items-center justify-between'}>
                  <h6 className='font-medium text-base'>Brands</h6>
                  <FiChevronDown className={open ? 'rotate-180 transform' : ''} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel>
                    <div className="space-y-1 border-t pt-3 max-h-[200px] overflow-y-scroll filter-scrollbar-div">
                      {brands.map((brand, index) =>
                        <div key={index} className='flex gap-1'>
                          <input checked={searchParams.get('brand') === brand.id} onChange={() => handleBrandChange(brand.id)}  type="checkbox" name='brand[]' id={`brand_${index}`} />
                          <label className='text-sm cursor-pointer' htmlFor={`brand_${index}`}>{brand.name}</label>
                        </div>)}
                    </div>

                  </Disclosure.Panel>
                </Transition>
              </React.Fragment>
            )}
          </Disclosure>
        </div>}

        {(models.length > 0) && 
        <div className='p-4 space-y-2'>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <React.Fragment>
                <Disclosure.Button className={'w-full text-left flex items-center justify-between'}>
                  <h6 className='font-medium text-base'>Models</h6>
                  <FiChevronDown className={open ? 'rotate-180 transform' : ''} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel>
                    <div className="space-y-1 border-t pt-3 max-h-[200px] overflow-y-scroll filter-scrollbar-div">
                      {models.map((model, index) =>
                        <div key={index} className='flex gap-1'>
                          <input checked={searchParams.get('model') === model.id} onChange={() => handleModelChange(model.id)}  type="checkbox" name='model[]' id={`model_${index}`} />
                          <label className='text-sm cursor-pointer' htmlFor={`model_${index}`}>{model.name}</label>
                        </div>)}
                    </div>

                  </Disclosure.Panel>
                </Transition>
              </React.Fragment>
            )}
          </Disclosure>
        </div>}

        {(specifications.length > 0) && 
        <div className='p-4 space-y-2'>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <React.Fragment>
                <Disclosure.Button className={'w-full text-left flex items-center justify-between'}>
                  <h6 className='font-medium text-base'>Specifications</h6>
                  <FiChevronDown className={open ? 'rotate-180 transform' : ''} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel>
                    <div className="space-y-1 border-t pt-3 max-h-[200px] overflow-y-scroll filter-scrollbar-div">
                      {specifications.map((item, index) =>
                        <div key={index} className='flex flex-col gap-1'>
                          <label htmlFor={item.name}>{item.name}</label>
                          <div className='flex flex-wrap gap-1'>
                            {item.options.map((option) => <button className='text-xs border rounded px-1 py-0.5'>{option}</button>)}
                          </div>
                        </div>)}
                    </div>

                  </Disclosure.Panel>
                </Transition>
              </React.Fragment>
            )}
          </Disclosure>
        </div>}

      </aside>
    </React.Fragment>
  )
}

export default FilterSidebar;
