import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import FilterSidebar from '../../components/Common/FilterSidebar/FilterSidebar'
import { useParams, useSearchParams } from 'react-router-dom'
import { getIndividualCategory } from '../../services/guest/category'
import ProductCard from '../../components/Cards/ProductCard/ProductCard'
import { getPosts } from '../../services/guest/post'

const Category = () => {

  const { slug } = useParams();

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [category, setCategory] = useState(null);
  const [posts, setPosts] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();

  const [limit, setLimit] = useState(20);

  const location = JSON.parse(localStorage.getItem('location'));

  const fetchData = async () => {
    const data = await getIndividualCategory(slug);
    const responseCategory = await data.data;

    let breadcrumbs = [];

      if (responseCategory) {
        breadcrumbs.push({
          label: 'Categories',
          path: '/categories',
          active: false
        });
        if (responseCategory?.parent_category) {
          breadcrumbs.push({
            label: responseCategory?.parent_category?.name,
            path: `/category/${responseCategory?.parent_category?.slug}`,
            active: false
          });
        }
        breadcrumbs.push({
          label: responseCategory?.name,
          path: `/category/${responseCategory?.slug}`,
          active: true
        });
      }

      setBreadcrumbs(breadcrumbs);
      
    setCategory(responseCategory)
    const response = await getPosts({
      search_query: searchParams.get('search_query') ? searchParams.get('search_query') : null,
      category_id: responseCategory?.id,
      locality: location?.locality,
      postal_code: location?.postalCode,
      city: location?.city,
      country: location?.country,
      limit: limit
    });
    if (response.status) {
      setPosts(await response.data);
    }
  }
  

  useEffect(() => {
    fetchData();
  }, [slug, searchParams.get('search_query')]);

  if (!category) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />
      <section>
        <div className='container lg:py-10 md:py-10 sm:py-5'>

          <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5'>

            <div className='lg:block md:block sm:hidden'>
              {category && <FilterSidebar />}
              <div>

              </div>
            </div>

            <div className='lg:col-span-3 md:col-span-2 sm:col-span-1'>
              <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-3'>
                {posts.map((post) => <ProductCard key={post.id} post={post} />)}
              </div>
            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default Category
