import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";
import { FiImage, FiMapPin, FiSearch } from 'react-icons/fi';
import { getAllConversation, getUnreadMessageCount } from '../../services/user/conversation';
import { toast } from 'sonner';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setChatCount } from '../../redux/actions/ChatCountAction';


const ChatConservation = ({ data }) => {

  const { id } = useParams();

  return (
    <React.Fragment>
      <Link to={`/chats/${data.id}`}>
        <figure className={`p-3 border-b ${(data.id === id) && 'bg-ascent bg-opacity-10'}`}>
          <div className='flex items-center justify-start gap-2'>
            <div className='relative w-fit h-fit'>
              <div className='h-[55px] w-[55px] rounded-md overflow-clip flex items-center justify-center border'>
                <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${data?.post?.thumbnail_image}`} className='h-full w-full' alt="" />
              </div>
              <div className='h-[22px] w-[22px] rounded-full overflow-clip flex items-center justify-center border absolute -bottom-1 -right-1'>
                <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${data?.post?.user?.profile_image}`} className='h-full w-full' alt="" />
              </div>
              {(data?.post?.posted_by_me == true) && <div className='px-1 py-0.5 rounded-sm bg-green-600 absolute -top-1 -left-1 text-[0.5rem] text-white'>
                My Post
              </div> }
            </div>
            <div className='w-full space-y-0.5'>
              <div className='flex items-center justify-between'>
                <h5 className='text-sm font-semibold'> {(data?.post?.title.length > 20) ? data?.post?.title.substr(0, 20) + '...' : data?.post?.title }</h5>
                {(data?.chat?.unread_message_count > 0) && <div className='h-5 w-5 bg-green-700 text-white rounded-full flex items-center justify-center text-[0.6rem] font-medium'>
                    <span>{data?.chat?.unread_message_count}</span>
                </div> }
              </div>
              <h6 className='text-xs text-gray-500 font-medium'>{ data?.post?.user?.name }</h6>
              <div className='flex items-center justify-between text-gray-500'>
                <div className='flex items-center justify-normal gap-0.5'>
                  {(data?.chat?.method === "SENT") && ((data?.chat?.checked_at === null) ? <IoCheckmarkOutline size={14} /> : <IoCheckmarkDoneOutline size={14} />)}

                  {(data?.chat?.type === "LOCATION") && <h6 className='text-[0.65rem] flex items-center justify-start'><FiMapPin className='mr-1' /> Location</h6>}

                  {(data?.chat?.type === "MEDIA") && <h6 className='text-[0.65rem] flex items-center justify-start'><FiImage className='mr-1' /> Image</h6>}

                  {(data?.chat?.type === "MESSAGE") && <h6 className='text-[0.65rem]'>{(data?.chat?.message?.length > 17) ? data?.chat?.message.substr(0, 17) + '...' : data?.chat?.message}</h6>}
                </div>
                <span className='text-[0.65rem]'>{moment(data?.chat?.sent_at).format('D/M/YYYY, h:mm A')}</span>
              </div>
            </div>
          </div>
        </figure>
      </Link>
    </React.Fragment>
  )
}

const ChatsList = ({ conversations, searchQuery, setSearchQuery }) => {

  const [searchBarStatus, setSearchBarStatus] = useState(true);

  return (
    <React.Fragment>
      <aside className='border-r h-full'>
        <div className='px-4 py-3 flex items-center justify-between'>
          <div className='space-y-0.5'>
            <h1 className='text-xl font-semibold'>My Chats</h1>
            <p className='text-[0.6rem] text-gray-500'>You have {conversations.length} conservations</p>
          </div>
          <button onClick={() => setSearchBarStatus(!searchBarStatus)}>
            <FiSearch size={20} className='stroke-slate-600' />
          </button>
        </div>
        <div className='p-3 border-t' hidden={searchBarStatus}>
          <div className='flex items-center justify-start rounded-md bg-slate-100'>
            <div className='p-3 pr-0'>
              <FiSearch size={15} className='stroke-slate-600' />
            </div>
            <input value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} type="search" className='bg-transparent p-3 outline-none w-full text-sm' placeholder='Search Chats' />
          </div>
        </div>
        <div className='border-t lg:max-h-[455px] overflow-y-scroll' id='conversation-scrollable-div'>
          {conversations.map((conversation) => <ChatConservation key={conversation.id} data={conversation} />)}
        </div>
      </aside>
    </React.Fragment>
  )
}

const Conversation = () => {

  const { id } = useParams();

  const [conversations, setConversations] = useState([]);

  const [chatSyncCount, setChatSyncCount] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  const fetchConversations = async () => {
    const response = await getAllConversation({
      search_query: searchQuery
    });
    if (response.status) {
      setConversations(await response.data);
    }
    else {
      toast.error(response.message)
    }
  }

  const fetchUnreadMessageCount = async () => {
    const data = await getUnreadMessageCount();
    if (await data.status) {
      dispatch(setChatCount((data.data?.unread_message_count) ? data.data.unread_message_count : 0));
    }
  }

  useEffect(() => {
    fetchConversations();
    fetchUnreadMessageCount();
  }, [id, chatSyncCount, searchQuery]);

  return (
    <React.Fragment>
      {/* <Breadcrumbs crumbs={[{
        label: "Chats",
        path: "/chats",
        active: false
      }]} /> */}
      <section>

        <div className='lg:container md:container lg:py-10 md:py-10 sm:py-0'>
          <figure className='lg:shadow-md md:shadow-md lg:border md:border lg:rounded-lg md:rounded-lg overflow-clip'>
            <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1'>
              <div className='lg:max-h-[500px]'>
                <div className='lg:block md:block sm:hidden'>
                  <ChatsList conversations={conversations} searchQuery={searchQuery} setSearchQuery={setSearchQuery}  />
                </div>
                <div className='lg:hidden md:hidden sm:block'>
                  { (id === undefined) &&  <ChatsList conversations={conversations} searchQuery={searchQuery} setSearchQuery={setSearchQuery} /> }
                </div>
              </div>
              <div className='lg:col-span-3 md:col-span-2 sm:col-span-1'>
                <Outlet context={{ setChatSyncCount, chatSyncCount }} />
              </div>
            </div>
          </figure>
        </div>

      </section>
    </React.Fragment>
  )
}

export default Conversation