import axios from "axios";

export const subscribeNewsletter = async (email) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/subscribe/newsletters`, {
            email: email
        }, {
            headers: {
                "Content-Type": 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
            
    } catch (error) {
        return false;
    }
}