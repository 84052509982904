import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, sendEmailVerificationOtp, userDetailsUpdate, verifyEmailVerificationOtp } from '../../../services/user/user';
import { AuthUserLogin } from '../../../redux/actions/AuthAction';
import { toast } from 'sonner';
import { BiLogOut } from 'react-icons/bi'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import { createSupportTicket, getSupportCategories } from '../../../services/user/supportTicket';
import { FiArrowRight, FiUploadCloud } from 'react-icons/fi';
import { LuLoader } from 'react-icons/lu';
import { getIndividualPost } from '../../../services/guest/post';

const SupportTicketCreate = () => {

  const auth = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const { post_id } = useParams();

  const navigate = useNavigate();

  const [supportCategories, setSupportCategories] = useState([]);
  const [postDetails, setPostDetails] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [formSubmitState, setFormSubmitState] = useState(false);

  const [inputFields, setInpuFields] = useState({
    post_id: post_id,
    support_category_id: '',
    title: '',
    description: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInpuFields({ ...inputFields, [name]: value });
  }

  const handleSelectImages = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    const filesJson = filesArray.map(file => { return file });
    if (files.length > 0) {
      setSelectedImages(filesJson);
    }
    else {
      setSelectedImages([]);
    }
  }

  const handleFormSubmit = async (event) => {
      setFormSubmitState(true);
      event.preventDefault();
      
      const form = new FormData(event.target);
  
      const data = {
        post_id: inputFields.post_id,
        support_category_id: inputFields.support_category_id,
        title: inputFields.title,
        description: inputFields.description,
        ticket_media: form.getAll('ticket_media'),
      }
  
      const response = await createSupportTicket(data);
      const result = await response;
      if (result.status) {
        toast.success(result.message);
        navigate('/dashboard/support-ticket');
      }
      else {
        setFormSubmitState(false);
        toast.error(result.message);
      }    
    }

  const fetchCategories = async () => {
    const categories = await getSupportCategories();
    if (categories.status) {
      setSupportCategories(await categories.data);
    }
  }

  const fetchPostDetails = async () => {
    const post = await getIndividualPost(post_id);
    if (post.status) {
      setPostDetails(await post.data);
    }
  }

  useEffect(() => {
    fetchCategories();
    if (post_id) {
      fetchPostDetails();
    }
  }, [post_id]);

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <figure className='lg:border md:border sm:border-t lg:shadow md:shadow lg:rounded-lg md:rounded-lg overflow-clip'>
          <div className='lg:px-5 md:px-0 py-4 space-y-1 border-b'>
            <h1 className='text-base font-medium'>Create a Ticket</h1>
            <p className='text-xs text-gray-500'>Please fill the required details to raise a support ticket</p>
          </div>
          { postDetails && <div className='border-b'>
            <div className='lg:px-5 md:px-0 py-4 flex items-center gap-3'>
              <div className='h-[70px] border rounded overflow-clip'>
                <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${postDetails?.thumbnail_image}`} className='h-full w-fit' alt="" />
              </div>
              <div className='space-y-0.5'>
                <h3 className='text-base font-semibold'>{postDetails?.title}</h3>
                <p className='text-xs text-gray-600'>Selling at {process.env.REACT_APP_CURRENCY_SYMBOL + parseInt(postDetails?.selling_price).toLocaleString('en-IN')}</p>
                <p className='text-xs text-gray-600'>Posted By {postDetails?.user?.name}</p>
              </div>
            </div>
          </div> }
          <div className='lg:px-5 md:px-0 py-5 space-y-1'>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

              <div className='input-group lg:col-span-2 md:col-span-2 sm:col-span-1'>
                <label htmlFor="title" className='text-sm font-medium mb-2'>Title <em>*</em></label>
                <input type="text" onChange={(event) => handleInputChange(event)} placeholder='Ticket Title' value={inputFields.title} className='input-box-md capitalize' name="title" required={true} />
              </div>

              <div className='input-group'>
                <label htmlFor="support_category_id" className='text-sm font-medium mb-2'>Category <em>*</em></label>
                <select name="support_category_id" id="support_category_id" onChange={(event) => handleInputChange(event)} className='input-box-md' required={true}>
                  <option value="">Select Category</option>
                  {supportCategories.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </select>
              </div>

              <div className='input-group lg:col-span-3 md:col-span-2 sm:col-span-1'>
                <label htmlFor="description" className='text-sm font-medium mb-2'>Description <em>*</em></label>
                <textarea name="description" onChange={(event) => handleInputChange(event)} rows={3} id="description" value={inputFields.description} className='input-box-md' placeholder='Describe your problem'></textarea>
              </div>

              <div className='input-group lg:col-span-3 md:col-span-2 sm:col-span-1'>
                <label htmlFor="ticket_media" className="input-label">Attatch Images <span>(Format: png, jpg, jpeg, webp)</span> <span>(Optional)</span></label>
                <div className="input-box-dragable">
                  <input multiple type="file" accept="image/jpeg, image/jpg, image/png, image/webp" name="ticket_media" onChange={(event) => handleSelectImages(event)} required max={20} min={1} />
                  <FiUploadCloud size={30} />
                  <span>Darg and Drop Image Files</span>
                </div>
                <div className='grid lg:grid-cols-4 sm:grid-cols-3 gap-3 mt-3'>
                  {selectedImages.map((image, index) =>
                    <div key={index} className='h-[120px] w-full rounded border bg-slate-50 flex items-center justify-center overflow-clip'>
                      <img src={URL.createObjectURL(image)} alt="" className='h-auto w-full' />
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
          <div className='lg:px-5 md:px-0 py-5 space-y-1 border-t'>
            <button disabled={formSubmitState} type='submit' className='btn-primary-sm flex items-center justify-center gap-2 lg:w-fit md:w-fit sm:w-full'>
              {formSubmitState && <LuLoader size={15} className='animate-spin' />}
              <span>Submit Ticket</span>
              <FiArrowRight strokeWidth={3} size={15} />
            </button>
          </div>
        </figure>
      </form>
    </React.Fragment>
  )
}

export default SupportTicketCreate