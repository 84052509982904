import axios from "axios";

export const getSupportCategories = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/support-ticket/category`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const createSupportTicket = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/support-ticket/create`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getIndividualSupportTicket = async (id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/support-ticket/details/${id}`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getAllSupportTickets = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/support-ticket`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

