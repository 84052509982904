import React from 'react'
import Home from '../views/Home/Home'
import Template from '../components/Layouts/Template'
import { Route, Routes } from 'react-router-dom'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { AuthUserLogin } from '../redux/actions/AuthAction'
import Auth from '../middleware/Auth'
import Login from '../views/Auth/Login/Login'
import Register from '../views/Auth/Register/Register'
import Account from '../views/Dashboard/Account/Account'
import Dashboard from '../components/Layouts/Dashboard'
import Setting from '../views/Dashboard/Setting/Setting'
import ForgotPassword from '../views/Auth/ForgotPassword/ForgotPassword'
import EmailVerification from '../views/Dashboard/EmailVerification/EmailVerification'
import Category from '../views/Category/Category'
import PostItemCategory from '../views/PostItemCategory/PostItemCategory'
import PostItemDetails from '../views/PostItemDetails/PostItemDetails'
import Posts from '../views/Dashboard/Posts/Posts'
import PostEdit from '../views/Dashboard/Posts/PostEdit/PostEdit'
import PostDetails from '../views/PostDetails/PostDetails'
import Wishlist from '../views/Dashboard/Wishlist/Wishlist'
import Conversation from '../components/Layouts/Conversation'
import ConversationPreview from '../views/Conversations/ConversationPreview'
import ConversationEmpty from '../views/Conversations/ConversationEmpty'
import CustomerSupport from '../views/CustomerSupport/CustomerSupport'
import SupportTickets from '../views/Dashboard/SupportTickets/SupportTickets'
import SupportTicketCreate from '../views/Dashboard/SupportTickets/SupportTicketCreate'
import SupportTicketDetails from '../views/Dashboard/SupportTickets/SupportTicketDetails'

const GuestRoutes = () => {

  const dispatch = useDispatch();

  const fetchUser = async () => {

    const token = localStorage.getItem('accessToken');

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user`, {
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      });
      if (response.data.status) {
        dispatch(AuthUserLogin(response.data.data))
      }
      else {
        localStorage.removeItem('accessToken');
      }
    } catch (error) {
      localStorage.removeItem('accessToken');
    }
  }

  if (localStorage.getItem('accessToken')) {
    fetchUser();
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path='/' element={<Template />}>
          <Route index={true} element={<Home />} />

          {/* Protected Routes */}
          <Route path='/' element={<Auth />}>
            <Route element={<Dashboard />}>
              <Route path='/dashboard/account' element={<Account/>} />
              <Route path='/dashboard/setting' element={<Setting/>} />
              <Route path='/dashboard/verify-email' element={<EmailVerification/>} />
              <Route path='/dashboard/post' element={<Posts/>} />
              <Route path='/dashboard/post/:id' element={<PostEdit/>} />
              <Route path='/dashboard/wishlist' element={<Wishlist />} />
              <Route path='/dashboard/support-ticket/' element={<SupportTickets/>} />
              <Route path='/dashboard/support-ticket/create/:post_id?' element={<SupportTicketCreate/>} />
              <Route path='/dashboard/support-ticket/details/:ticket_id' element={<SupportTicketDetails/>} />
            </Route>
            <Route element={<Conversation />}>
              <Route path='/chats' element={<ConversationEmpty/>} />
              <Route path='/chats/:id' element={<ConversationPreview/>} />
            </Route>
            <Route path='/post-item' element={<PostItemCategory />} />
            <Route path='/post-item/details/:category_id' element={<PostItemDetails />} />
          </Route>
          
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/category/:slug' element={<Category />} />
          <Route path='/post/:id' element={<PostDetails />} />
          <Route path='/customer-support/:post_id?' element={<CustomerSupport />} />

        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default GuestRoutes