import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSupportTickets } from '../../../services/user/supportTicket';
import moment from 'moment';
import { FiArrowRight, FiCalendar } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const SupportTicketCard = ({ ticket }) => {
  return (
    <React.Fragment>
      <figure className='lg:px-5 md:px-5 sm:px-0 py-5 border-b'>
        <div className='space-y-2'>
          <div className='flex items-center justify-between'>
            <h1 className='text-sm'>{ticket.ref_no}</h1>
            <div>
              {ticket.ticket_status === 'PENDING' && <span className='text-[0.6rem] bg-orange-200 text-orange-800 px-2 py-1 rounded font-medium'>{ticket.ticket_status}</span>}
            </div>
          </div>
          <p className='text-base font-medium'>{ticket.title}</p>
          <div className='flex items-center justify-start gap-1 text-gray-600'>
            <FiCalendar size={12} />
            <p className='text-xs'>{moment(ticket.created_at).format('D/M/YYYY, h:mm A')}</p>
          </div>
          <div>
            <Link to={`/dashboard/support-ticket/details/${ticket.id}`} className='flex items-center justify-start gap-1 font-medium text-ascent'>
              <span className='text-xs'>View Details</span> 
              <FiArrowRight strokeWidth={2.5} size={15} />
            </Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

const SupportTickets = () => {

  const auth = useSelector((state) => state.AuthReducer);

  const [supportTickets, setSupportTickets] = useState([]);

  const fetchSupportTickets = async () => {
    const response = await getAllSupportTickets();
    if (response.status) {
      setSupportTickets(response.data);
    }
  }

  useEffect(() => {
    fetchSupportTickets();
  }, []);

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <figure className='lg:border md:border sm:border-t lg:shadow md:shadow lg:rounded-lg md:rounded-lg overflow-clip'>
        <div className='lg:px-5 md:px-0 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Support Tickets</h1>
          <p className='text-xs text-gray-500'>Manage your all customer support tickets</p>
        </div>
        <div className='space-y-1'>
          {supportTickets.map((ticket) => <SupportTicketCard ticket={ticket} key={ticket.id} />)}
        </div>
      </figure>
    </React.Fragment>
  )
}

export default SupportTickets