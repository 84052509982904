import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Disclosure, Transition } from '@headlessui/react'
import 'swiper/css';
import 'swiper/css/navigation';
import { FiChevronDown, FiExternalLink, FiMap, FiMapPin, FiMessageCircle, FiMessageSquare } from 'react-icons/fi';


const CustomerSupport = () => {

  const { post_id } = useParams();

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={[
        {
          label: 'Customer Support',
          path: '/customer-support',
          active: true
        }
      ]} />
      <section>
        <div className='container lg:py-16 md:py-10 sm:py-7 lg:space-y-10 sm:space-y-5'>

          <div>
            <h1 className='lg:text-3xl md:text-2xl sm:text-2xl font-bold'>Customer Support</h1>
          </div>

          <div>
            <p className='text-sm text-gray-700'>We are striving diligently to enhance your experience. For any particular inquiries, kindly refer to our Help center. If your concern remains unresolved, please do not hesitate to email us at support@zappdeal.com to inform us how we can enhance our services.</p>
          </div>

          <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-7'>

            <figure className='border rounded-lg'>
              <div className='p-5 space-y-4'>
                <div className='flex justify-between lg:flex-row md:flex-row sm:flex-col gap-5'>
                  <div className='space-y-0.5'>
                    <h3 className='text-lg font-semibold'>Customer Care</h3>
                    <p className='text-sm opacity-70 font-medium'>Telephone : <a href="tel:912235740444" className='text-ascent-dark'>+(91) 22357-40444</a></p>
                    <p className='text-sm opacity-70 font-medium'>Email : <a href="mailto:support@zappdeal.com" className='text-ascent-dark'>support@zappdeal.com</a></p>
                  </div>
                  <div className='space-y-2 text-center'>
                    <p className='px-4 py-2 bg-ascent text-white text-sm font-medium rounded'>Monday - Saturday</p>
                    <p className='text-gray-700 text-sm'>11:00 AM - 7:00 PM</p>
                  </div>
                </div>
                <div className='space-y-3'>
                  <Disclosure>
                    {({ open }) => (
                      <div className='bg-slate-50 rounded'>
                        <Disclosure.Button className={'flex justify-between items-center w-full px-4 py-3 bg-indigo-100 rounded text-sm font-medium text-gray-800'}>
                          <div className='flex items-center justify-start gap-2'>
                            <FiMapPin strokeWidth={2.5} size={15} />
                            <span>Office Address</span>
                          </div>
                          <FiChevronDown strokeWidth={3} size={20} className={`${open ? 'rotate-90 transform' : ''} stroke-gray-700`} />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel>
                            <div className='p-3.5'>
                              <p className='text-sm text-gray-700'>Corporate Office - 308, 3rd Floor, Landmark Above HDFC Bank, Khar Pali Rd, Bandra West, Mumbai, Maharashtra 400050</p>
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    )}
                  </Disclosure>
                  <Disclosure>
                    {({ open }) => (
                      <div className='bg-slate-50 rounded'>
                        <Disclosure.Button className={'flex justify-between items-center w-full px-4 py-3 bg-indigo-100 rounded text-sm font-medium text-gray-800'}>
                          <div className='flex items-center justify-start gap-2'>
                            <FiMessageCircle strokeWidth={2.5} size={15} />
                            <span>Order Update Queries</span>
                          </div>
                          <FiChevronDown strokeWidth={3} size={20} className={`${open ? 'rotate-90 transform' : ''} stroke-gray-700`} />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel>
                            <div className='p-3.5'>
                              <p className='text-sm text-gray-700'>Please check your Mail / SMS for timely updates. To get answers to your Quick Queries visit FAQs</p>
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    )}
                  </Disclosure>
                </div>
              </div>
            </figure>

            <figure className='border rounded-lg'>
              <div className='p-5 space-y-4'>
                  
                <div className='space-y-0.5'>
                    <h3 className='text-lg font-semibold'>Need Assistance? Raise a Ticket</h3>
                    <p className='text-sm text-gray-700'>Our customer support team is here to help! If you’re facing an issue or have a question, simply raise a ticket, and we’ll get back to you as soon as possible. </p>
                  </div>
                  <div>
                    <Link to={ post_id ? `/dashboard/support-ticket/create/${post_id}` : '/dashboard/support-ticket/create' }>
                    <button className='btn-primary-md flex  items-center justify-center w-fit gap-2'>
                      <span>Raise a Ticket</span>
                      <FiExternalLink />
                    </button></Link>
                  </div>
              </div>
            </figure>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default CustomerSupport;
